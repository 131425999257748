import * as React from "react"
import { Link } from "gatsby"

import ThemeContext from "../context/ThemeContext"

const Maintenance = () => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <section
          className={theme.dark ? " dark dark-5 " : " bg-danger text-light "}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <h2>We Are Updating this Page</h2>
                <h5 className="text-danger">
                  Sorry! we are updating this page and its not available right
                  now. Please visit this page later. Inconvenience is highly
                  regretted.
                </h5>
                <p>You may also</p>
                <Link to="/contact" className="btn btn-success btn-rounded">
                  Contact Us <i className="icon-chevron-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
    </ThemeContext.Consumer>
  )
}
export default Maintenance
